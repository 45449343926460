<template>
	<edit-template @confirm="onPreserve" @cancel="onBack" :cancelRemind="false" confirmText="保存" cancelText="取消">

    <el-form ref="form" :model="form" :rules="rules" class="form-body" label-width="160rem" style="padding-top: 70rem;padding-left: 100rem">
			<el-form-item label="商品名称" prop="goods_name">
				<el-input v-model="form.goods_name" class="independentStyle" placeholder="请输入商品名称"></el-input>
			</el-form-item>
			<el-form-item label="商品类型" prop="goods_category">
				<el-select v-model="form.goods_category" placeholder="请选择上商品类型" value="" @change="goodsCategoryChange">
					<el-option v-for="(item,index) in goodsCategory" :label="item" :value="index+''"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="收费方式" prop="goods_type">
				<el-select v-model="form.goods_type" placeholder="请选择收费方式" value="">
					<el-option label="一次收费" value="1"></el-option>
					<el-option label="多次收费" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="`${form.goods_category === '0' ? '普高线上学费价格' : '商品价格'}`" prop="goods_price">
				<el-input v-model="form.goods_price" :maxlength="20" :placeholder="`${form.goods_category === '0' ? '请输入普高线上学费价格' : '请输入商品价格'}`"></el-input>
			</el-form-item>
			<el-form-item v-if="form.goods_category === '0'" label="普高线下学费价格" prop="goods_price_below">
				<el-input v-model="form.goods_price_below" :maxlength="20" placeholder="请输入普高线下学费价格"></el-input>
			</el-form-item>
		</el-form>


</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  components: {},

  data() { // 这里存放数据
    const validateNumber = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error(`请输入${this.form.goods_category === '0' ? '线上普高线价格' : '商品价格'}`))
      }
      const str = value + ''
      const arr = str.split('.')
      if(arr.length > 1 && arr[1].length > 2) {
        return callback(new Error('最多输入2位小数'))
      }
      const num = Number(value)
      if(this.form.goods_category === '12') {
        if(num >= 1000000 || num <= -1000000) {
          return callback(new Error('商品价格限制为：-999999.99~999999.99元'))
        }
        return callback()
      }
      if(num < 0 || num >= 100000) {
        return callback(new Error('商品价格限制为：0~999999.99元'))
      }
      callback()
    }
		return {
			form: {
				goods_name: '',
				type: '',
				price: '',
				goods_category: '',
				goods_price: '',
			},
			rules: {
				goods_name: [
					{required: true, message: '请输入商品名称', trigger: 'change'},
					{min: 1, max: 20, message: '请输入1至20个字符', trigger: 'change'},
				],
				goods_type: [
					{required: true, message: '请选择收费方式', trigger: 'change'},
				],
				goods_category: [
					{required: true, message: '请选择商品类型', trigger: 'change'},
				],
				goods_price: [
          {required: true, validator: validateNumber, trigger: 'change'},
				],
				goods_price_below: [
          {required: true, validator: validateNumber, trigger: 'change'},
				],
			},
			goodsCategory: []
		};
  },

  mounted() { // 生命周期 - 挂载完成（可以访问 DOM 元素）
		this.$_register.get('api/recruit/goods-manage/get-goods-category').then(res=>{
			this.goodsCategory = res.data.data
		})
  },

  activated() { // 如果页面有 keep-alive 缓存功能,这个函数会触发  进入的时候触发
  },

  computed: { // 计算属性 类似于 data 概念
  },

  methods: { // 方法合集
    onBack() {
      this.$confirm('是否取消新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.$router.back();
			});
		},
		onPreserve() {
			this.$refs.form.validate((valid) => {
				if (valid) {
					this.$_register.post('api/recruit/goods-manage/add-goods', this.form).then(res => {
						if (res.data.status === 0) {
							this.$message.success('新增成功');
							this.$router.back();
							this.$store.commit('setPage', 1)
						} else this.$message.error(res.data.msg)
					})
				}
			});
		},
		onChangePrice(v) {
			this.form.goods_price = v.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace(/^0\d[0-9]*/g, '').replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
		},
		onChangeGoodsPriceBelow(v) {
			this.form.goods_price_below = v.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace(/^0\d[0-9]*/g, '').replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
		},
		goodsCategoryChange(val) {
			this.$refs.form.validateField('goods_price')
		}
	},
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1140rem) {
	.independentStyle {
		width: 30.13888888888888vw !important;
		/*min-width:250rem;*/
		max-width: 100%;
	}
}

@media only screen and (max-width: 1139rem) {
	.independentStyle {
		width: 500rem !important;
		max-width: 100%;
	}
}
</style>
